import React from "react"

import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import "./404.scss"

const NotFoundPage = () => {
  const { formatMessage } = useIntl()
  return (
    <>
      <SEO title={`404: ${formatMessage({ id: "notFound.title" })}`} />
      <div className="not-found">
        <h1 className="not-found__title">
          {formatMessage({ id: "notFound.title" })}
        </h1>
        <p className="not-found__message">
          {formatMessage({ id: "notFound.message" })}
        </p>
      </div>
    </>
  )
}

export default NotFoundPage
