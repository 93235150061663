/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { useIntl } from "gatsby-plugin-intl"

function SEO({ description,keywords, meta, title, location }) {
  const site = useSelector(state => state.seo.data) || {}
  const { locale } = useIntl()

  const metaDescription = description || site.description
  const metaKeywords = keywords || site.keywords

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: site.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:keywords`,
          content: metaKeywords,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `Consorcio EDER`,
        },
        {
          name: `twitter:title`,
          content: site.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: '1BWhF_AkEVhX6Ny7Mh3xd7sqK2j7zf9rtgZB36vLM6Q',
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  location: PropTypes.object,
}

export default SEO
